import React, { useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Checkbox, FormControlLabel, Grid, TextField } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Dialog from "../../../waybee-ui/Dialog";
import Button from "../../../waybee-ui/Button";
import IconPicker from "../../../components/IconPicker";

const ContentGrid = styled(Grid)`
  padding: 45px 65px 60px 65px;
`;

const Label = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 4px;
  margin-top: 15px;
`;

const LinksDialog = ({ open, onClose, onSave, values }) => {
  const { register, getValues, watch, control, reset } = useForm({
    defaultValues: {
      public: true
    }
  });

  const onSaveLink = () => {
    const formValues = getValues();
    onSave(formValues);
  };

  useEffect(() => {
    if (open) {
      if (values) {
        reset(values);
      } else {
        reset({
          name: "",
          icon: "link",
          link: "",
          public: true
        });
      }
    }
  }, [open, reset]);

  const showDestination = !watch("public");

  return (
    <Dialog open={open} onClose={onClose} closeButton maxWidth="sm" fullWidth>
      <ContentGrid container spacing={2}>
        <Grid item xs={2}>
          <Label>Ícone</Label>
          <Controller
            name="icon"
            control={control}
            defaultValue="link"
            render={({ field }) => <IconPicker {...field} />}
          />
        </Grid>

        <Grid item xs={10}>
          <Label>Texto do Botão</Label>
          <TextField
            {...register("name", { required: "Campo obrigatório" })}
            variant="outlined"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Label>Link</Label>
          <TextField
            {...register("link", { required: "Campo obrigatório" })}
            variant="outlined"
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <FormControlLabel
            control={(
              <Controller
                name="public"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={e => field.onChange(e.target.checked)}
                  />
                )}
              />
            )}
            label="Link público"
          />
        </Grid>

        <Grid item xs={6}>
          <FormControlLabel
            control={(
              <Controller
                name="inAppLink"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={e => field.onChange(e.target.checked)}
                  />
                )}
              />
            )}
            label="Abrir link no app"
          />
        </Grid>

        {showDestination && (
          <Grid item xs={12}>
            <Label>Quem pode ver o link?</Label>
            <FormControlLabel
              control={(
                <Controller
                  name="destinationStudent"
                  control={control}
                  defaultValue
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={e => field.onChange(e.target.checked)}
                    />
                  )}
                />
              )}
              label="Alunos"
            />
            <FormControlLabel
              control={(
                <Controller
                  name="destinationFinantial"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={e => field.onChange(e.target.checked)}
                    />
                  )}
                />
              )}
              label="Responsáveis Financeiros"
            />
            <FormControlLabel
              control={(
                <Controller
                  name="destinationPedagogical"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={e => field.onChange(e.target.checked)}
                    />
                  )}
                />
              )}
              label="Responsáveis Pedagógico"
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Button onClick={onSaveLink} variant="contained" color="secondary">
            Salvar
          </Button>
        </Grid>
      </ContentGrid>
    </Dialog>
  );
};

LinksDialog.defaultProps = {
  values: null
};

LinksDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  values: PropTypes.shape()
};

export default LinksDialog;
